import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BaseLabel from './Label';
import { ErrorUI } from '../Molecules/ErrorUI';
import { theme as Rtheme } from '../Utils/theme';

const Wrapper = styled.div`
  display: table;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  padding-bottom: 10px;
`;

const checkedStyles = css`
  border: 2px solid ${Rtheme.colors.borderColorFocus};
  background: ${Rtheme.colors.borderColorFocus};
`;

const uncheckedStyles = css`
  border: 2px solid ${Rtheme.colors.borderColor};
  background: transparent;
`;

const disabledStyles = css`
  opacity: 0.25;
`;

const enabledStyles = css`
  opacity: 1;
  pointer-events: initial;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  ${props => (props.checked ? checkedStyles : uncheckedStyles)};
  ${props => (props.isDisabled ? disabledStyles : enabledStyles)};
  transition: all 150ms;
  margin-right: 10px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'initial')};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Label = styled(BaseLabel)`
  cursor: pointer;
  filter: ${props =>
    props.isDisabled ? 'contrast(0%) brightness(30%) invert(70%)' : 'none'};
`;

const Checkbox = ({
  className,
  children,
  trueFor,
  meta,
  useArrayValue,
  labelColor,
  labelWeight,
  isDisabled,
  ...props
}) => {
  const { onChange, value } = props.input;

  function handleChange() {
    if (useArrayValue) {
      if (value.includes(trueFor)) {
        onChange(value.filter(item => `${item}` !== `${trueFor}`));
      } else {
        onChange([...value, trueFor]);
      }
    } else {
      onChange(!value);
    }
  }
  return (
    <>
      <Wrapper>
        <CheckboxContainer
          className={className}
          onClick={() => handleChange()}
          isDisabled={isDisabled}
        >
          <HiddenCheckbox
            checked={useArrayValue ? value.includes(trueFor) : value}
            {...props}
          />
          <StyledCheckbox
            checked={useArrayValue ? value.includes(trueFor) : value}
            isDisabled={isDisabled}
          >
            <Icon viewBox="0 0 25 25">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <Label
            color={labelColor}
            fontWeight={labelWeight}
            isDisabled={isDisabled}
          >
            {children}
          </Label>
        </CheckboxContainer>
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
};

Checkbox.defaultProps = {
  className: '',
  trueFor: 30,
  onChange: () => {},
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
    value: [],
  },
  useArrayValue: true,
  labelColor: '#314143',
  isDisabled: false,
  children: undefined,
  labelWeight: undefined,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  trueFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  useArrayValue: PropTypes.bool,
  labelColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  labelWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

export { Checkbox };
