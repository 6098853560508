import styled from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const ErrorText = styled.span`
  padding-left: 10px;
  display: inline-block;
  white-space: nowrap;
  color: ${Rtheme.colors.fontColorError};
`;

export default ErrorText;
