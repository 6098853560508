/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as BaseLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import {
  width,
  minWidth,
  height,
  space,
  position,
  layout,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Heading } from '../Atoms/Typographic';
import { Hr } from '../Organisms/bigCalendar/Helper';
import { IconNew } from '../Atoms';

const Touch = keyframes`
  0% {
    transform: scale3d(0,0,0);
  }
  100% {
    transform: scale3d(1,1,1);
  }
`;

const StyledLink = styled(BaseLink)`
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  padding-left: ${p =>
    p.is_sub === 'true'
      ? p.subItemCustomPadding || '50px'
      : p.itemCustomPadding || '40px'};
  position: relative;
  color: ${themeGet('colors.fontColorSecondary')};
  display: block;
  height: 60px;
  transition: all 0.3s ease;
  pointer-events: ${p => (!p.to ? 'none' : 'initial')};
  &.active {
    &:before {
      content: '';
      display: ${p => (p.isMobileView ? 'none' : 'block')};
      position: absolute;
      transition: all 0.3s ease;
      top: -8px;
      left: 0;
      height: 42px;
      width: 8px;
      border-radius: 0 16px 16px 0;
      background: #00bbb5;
      box-shadow: 1px 2px 7px 0 #2ce1db;
      animation: ${Touch} 0.2s ease forwards;
    }
  }

  &.active > p {
    color: ${themeGet('colors.fontColor')};
  }
  ${layout}
`;

const StyledIcon = styled(IconNew)`
  color: #7a979b;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
`;

function Link({
  to,
  children,
  is_sub,
  has_sub_route,
  highlight,
  height,
  customStyle,
  target,
}) {
  return (
    <StyledLink
      exact
      is_sub={`${is_sub}`}
      has_sub_route={has_sub_route}
      to={to}
      activeClassName={highlight ? null : 'active'}
      target={target}
      {...customStyle}
    >
      {children}
    </StyledLink>
  );
}

const SidebarWrapper = styled.div`
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  background-color: ${themeGet('colors.bgActive')};
  max-width: 210px;
  width: 210px;
  padding-top: 40px;
  height: calc(100% - 90px);
  ${width}
  ${minWidth}
  ${space}
  ${position}
`;

Link.defaultProps = {
  children: undefined,
  has_sub_route: [],
  highlight: false,
  height: "51px",
  customStyle: undefined,
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  is_sub: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  has_sub_route: PropTypes.arrayOf(PropTypes.any),
  highlight: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customStyle: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

function renderLinks(data, is_sub, customStyle) {
  return data.map(item => (
    <React.Fragment key={item.id}>
      <Link
        key={item.id}
        to={item.url || ''}
        is_sub={is_sub}
        has_sub_route={item.subRoutes}
        highlight={!!item.disableHighlight}
        customStyle={customStyle}
        target={item.target}
      >
        {item.hasDivider && (
          <Hr
            height="1px"
            border="none"
            bg="#d2e9e7"
            mb="16px"
            mr="16px"
            mt="0"
          />
        )}
        <Heading muted={!item.url} size={3}>
          {item.label}
          {item.hasIcon && (
            <StyledIcon className="material-icons">launch</StyledIcon>
          )}
        </Heading>
      </Link>
      {item.subRoutes && renderLinks(item.subRoutes, true, customStyle)}
    </React.Fragment>
  ));
}

const Sidebar = ({
  sidebarItems,
  p,
  mt,
  minWidth,
  width,
  position,
  zIndex,
  customStyle,
}) => {
  return (
    <SidebarWrapper
      p={p}
      mt={mt}
      width={width}
      position={position}
      minWidth={minWidth}
      zIndex={zIndex}
    >
      {renderLinks(sidebarItems, false, customStyle)}
    </SidebarWrapper>
  );
};

Sidebar.defaultProps = {
  height: '51px',
  sidebarItems: [
    {
      id: 1,
      key: 1,
      url: '/',
      label: '一般',
    },
    {
      id: 2,
      key: 2,
      url: '/meetings',
      label: '会議',
    },
    {
      id: 3,
      key: 3,
      url: '/business_hours',
      label: '日時設定',
      disableHighlight: true,
      subRoutes: [
        {
          id: '4-1',
          key: '4-1',
          url: '/reservation_page_settings',
          label: '営業日時',
        },
        {
          id: '4-2',
          key: '4-2',
          url: '/reservation_completion_statement',
          label: '詳細設定',
        },
      ],
    },
    {
      id: 4,
      key: 4,
      url: '/reservation_form',
      label: '予約フォーム',
      disableHighlight: true,
      subRoutes: [
        {
          id: '4-1',
          key: '4-1',
          url: '/reservation_page_settings',
          label: '予約フォーム設定',
        },
        {
          id: '4-2',
          key: '4-2',
          url: '/reservation_completion_statement',
          label: '予約完了文',
        },
      ],
    },
    {
      id: 5,
      key: 5,
      url: '/Notice',
      label: '通知',
      disableHighlight: true,
      subRoutes: [
        {
          id: '5-1',
          key: '5-1',
          url: '/chat_notifications',
          label: '社内チャット',
        },
        {
          id: '5-2',
          key: '5-2',
          url: '/email_notifications',
          label: 'お客様へのメール',
        },
      ],
    },
    {
      id: 6,
      key: 6,
      url: '/Integrations',
      label: '外部連携',
      disableHighlight: true,
      subRoutes: [
        {
          id: '6-1',
          key: '6-1',
          url: '/salesforce',
          label: 'Salesforce',
        },
      ],
    },
  ],
  p: undefined,
  mt: undefined,
  width: '210px',
  minWidth: undefined,
  pt: '40px',
  zIndex: '1',
  position: 'fixed',
};

Sidebar.propTypes = {
  sidebarItems: PropTypes.arrayOf(PropTypes.any),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export { Sidebar };
