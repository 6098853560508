import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorText } from '../Atoms';
import { generateTag } from '../Utils/test';

const Wrapper = styled.div`
  position: absolute;
`;

export function ErrorUI({ meta: { touched, error, warning }, ...restProps }) {
  return (
    <Wrapper {...generateTag(restProps, 'wrapper')}>
      {touched &&
        ((error && (
          <ErrorText {...generateTag(restProps, 'error_text')}>
            {error}
          </ErrorText>
        )) ||
          (warning && (
            <span {...generateTag(restProps, 'warning_text')}>{warning}</span>
          )))}
    </Wrapper>
  );
}

ErrorUI.defaultProps = {
  meta: {
    touched: undefined,
    error: undefined,
    warning: undefined,
  },
};

ErrorUI.propTypes = {
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
